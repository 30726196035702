.logo-wrapper {
	padding-top: 5rem;
	display: flex;
	flex: 1 1 200px;
	width: 100%;
	place-content: center;
	&__logo-image {
		height: clamp(3rem, 12vw, 20rem);
	}
	&__logo-text {
		height: clamp(3rem, 16vw, 20rem);
	}
}

.app-section {
	padding: 0rem 10vw;
	margin-top: 0rem;
	&__card-container {
		display: flex;
		gap: 2vw;
		width: 100%;
		margin-top: 2rem;
		justify-content: center;
		flex-wrap: wrap;
		flex-basis: 200px;
		div {
			flex-grow: 1;
		}
		&__card {
			flex-shrink: 1;
			background: rgb(2, 0, 36);
			background: linear-gradient(
				180deg,
				rgba(2, 0, 36, 0.6) 0%,
				rgba(9, 9, 121, 0.7) 42%,
				rgba(90, 126, 162, 0.8) 100%
			);
			border-radius: 16px;
			box-shadow: 0 4px 30px rgba(48, 44, 44, 0.1);
			backdrop-filter: blur(3px);
			-webkit-backdrop-filter: blur(3px);
			border: 1px solid rgba(43, 67, 96, 0.19);
			color: #dedede;
			transition: 0.2s ease-in;
			&:hover {
				backdrop-filter: blur(12px);
				-webkit-backdrop-filter: blur(12px);
				scale: 1.1;
				font-weight: 600;
			}
			&__heading {
				margin: 1rem 1.5rem;
				color: aquamarine;
			}
			&__list {
				margin: 0.5rem 2rem;
				&__item {
					&:hover {
						color: #ff2022;
						transition: 0.3s ease-in;
					}
				}
			}
		}
	}
}
