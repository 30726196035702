.ninja-header-logo {
	height: 3rem;
}
.header-links {
	font-weight: 700;
	font-size: 22px;
	&:hover {
		color: #ff2022;
		transition: 0.3s ease-in;
	}
}
