.App {
    background-image: url("./assets/itachi-uchiha-ninja.jpeg");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    width: 100%;
    min-height: 50rem;
    height: 100vh;
}

.body {
    margin: 0px;
}